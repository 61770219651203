import React from "react";
import styled, { css } from "styled-components";
import { graphql, Link as GatsbyLink } from "gatsby";
import GatsbyImage from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import { hideVisually } from "polished";

function StandardPageTemplate({ data }) {
  const { title, body, childContent = [] } = data.contentfulPage;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle>{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      <MaxWidthBox>
        <RichText>{renderRichText(body)}</RichText>
      </MaxWidthBox>
      {childContent && (
        <FlexContainer>
          <CuratorsLinks>
            {childContent.map(curator => {
              const { id, logoOnDark = null, slug, page, name } = curator;
              const pageSlug = page && page[0].slug;
              return (
                <CuratorLink key={id} to={`/${pageSlug}/${slug}/`}>
                  {logoOnDark && <CuratorLogo fluid={logoOnDark.fluid} />}
                  <HiddenName>{name}</HiddenName>
                </CuratorLink>
              );
            })}
          </CuratorsLinks>
        </FlexContainer>
      )}
    </Layout>
  );
}

export default StandardPageTemplate;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CuratorsLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;
  margin-right: -1rem;
  margin-left: -1rem;

  @media (min-width: 32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }
`;

const CuratorLink = styled(GatsbyLink)`
  padding: 1rem;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const curatorLogoHeight = 5;

const curatorLogoDimensions = css`
  width: ${({ fluid }) => curatorLogoHeight * fluid.aspectRatio}rem;
  height: ${curatorLogoHeight}rem;
`;

const CuratorLogo = styled(GatsbyImage)`
  ${curatorLogoDimensions};
  display: block;
`;

const HiddenName = styled.span`
  ${hideVisually}
`;

export const pageQuery = graphql`
  query CuratorsPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      body {
        raw
      }
      childContent {
        ... on ContentfulCurator {
          id
          name
          slug
          logoOnDark {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          page {
            slug
            id
          }
        }
      }
    }
  }
`;
